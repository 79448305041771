import * as React from "react";
import { Container, Row, Col, Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap"
import audit from '../../content/assets/website-audit.svg'
import review from '../../content/assets/digital-presence-review.svg'
import consultation from '../../content/assets/consultation.svg'


const Consultation = () => {


  return (
    <Container className=''>
      <Row className='mt-5 mb-5'>
        <Col md={12} className=''>
          <h2>We audit and review websites and digital presence.</h2>
        <p>Wolf on Moon offers consultation on website performance and decisions involving your brand's digital presence.</p>
        </Col>
        <Col lg={6} md={12} className='pt-5 pb-3'>
          <Card className='p-5'>
            <Row>
              <Col md={12}>
                <h3>Website audit and health check</h3>
                <hr />
              </Col>
              <Col md={12}>
                <p>We run a series of checks on your website and provide you with a report that details the health of your website.</p>
              </Col>
              <Col md={{ span: 10, offset: 1}}>
                <Button href='https://wlfnmn.typeform.com/to/XPQatH1g' className='btn-block' variant='primary'>Request an audit</Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={6} md={12} className='pt-5 pb-3'>
          <Card className='p-5'>
            <Row>
              <Col md={12}>
                <h3>Digital presence review</h3>
                <hr />
              </Col>
              <Col md={12}>
                <p>We review the totality of your public digital presence and make informed recommendations based on data and best practices.</p>
              </Col>
              <Col md={{ span: 10, offset: 1}}>
                <Button href='https://wlfnmn.typeform.com/to/DE3ZWSuL' className='btn-block' variant='primary'>Request a review</Button>
              </Col>
            </Row>
          </Card>
        </Col>
        </Row>
        <Row className='mt-5 mb-5'>
        
      </Row>
    </Container>
  );
};


export default Consultation